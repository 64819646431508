<template>
    <div class="dropdown d-inline-block">
        <button :class="['btn', 'btn-sm', `btn-falcon-${error ? 'danger' : 'default'}`, 'dropdown-toggle']"
                :disabled="loading"
                data-toggle="dropdown"
                aria-label="Print document"
                aria-haspopup="true"
                aria-expanded="false">
            <span v-show="!error">
                <i class="fa fa-print fa-fw" />
            </span>
            <span v-show="error">
                <i class="fa fa-exclamation-triangle fa-fw" />
            </span>
            <slot />
        </button>
        <div class="dropdown-menu dropdown-menu-right text-nowrap py-2">
            <div v-if="printers.length === 0" class="dropdown-item-text font-weight-normal">
                No printers available
            </div>
            <template v-if="allowDefault">
                <button class="dropdown-item font-italic" @click="print(null)">
                    Use my default printer
                </button>
                <div class="dropdown-divider" />
            </template>
            <button v-for="printer in printers" :key="printer.id" class="dropdown-item" @click="print(printer.id)">
                {{ printer.name }}
            </button>
        </div>
    </div>
</template>

<script>
    import { getDefaultPrinters, getPrinterList } from '../services/PrinterService';
    import axios from 'axios';

    export default {
        name: 'PrintDropdownButton',
        props: {
            printUrl: {
                type: String,
                required: true,
            },
            paperSize: {
                type: String,
                validator: val => ['LETTER', 'LABEL'].includes(val),
                required: true,
            },
        },
        data() {
            return {
                loading: true,
                error: null,
                printers: [],
                allowDefault: false,
            };
        },
        async mounted() {
            try {
                const [{ data: printers }, { data: defaultPrinters }] =
                    await Promise.all([getPrinterList(), getDefaultPrinters()]);

                this.printers = printers.filter(printer => printer.paper_size === this.paperSize);

                if (this.paperSize === 'LETTER') {
                    this.allowDefault = defaultPrinters.default_letter_printer != null;
                } else if (this.paperSize === 'LABEL') {
                    this.allowDefault = defaultPrinters.default_label_printer != null;
                }
            } catch (error) {
                this.error = error;
            } finally {
                this.loading = false;
            }
        },
        methods: {
            async print(printerID) {
                this.loading = true, this.error = null;
                try {
                    await axios.post(this.printUrl, printerID ? { printer_id: printerID } : undefined);
                } catch (error) {
                    this.error = error;
                } finally {
                    this.loading = false;
                }
            },
        },
    };
</script>
