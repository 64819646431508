<template>
    <button :class="['btn', 'btn-sm', `btn-falcon-${error ? 'danger' : 'default'}`]"
            :disabled="loading"
            @click="downloadFile"
            aria-label="Download document">
        <span v-show="!error">
            <i class="fa fa-file-download fa-fw" />
        </span>
        <span v-show="error">
            <i class="fa fa-exclamation-triangle fa-fw" />
        </span>
        <slot />
    </button>
</template>

<script>
    import { downloadFile } from '../util';

    export default {
        name: 'FileDownloadButton',
        props: {
            downloadUrl: {
                type: String,
                required: true,
            },
            fileName: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                loading: false,
                error: false,
            };
        },
        methods: {
            async downloadFile() {
                this.loading = true, this.error = false;

                try {
                    await downloadFile(this.downloadUrl, this.fileName);
                } catch {
                    this.error = true;
                } finally {
                    this.loading = false;
                }
            },
        },
    };
</script>
