<template>
    <div class="btn-group btn-group-sm" role="group" aria-label="Document controls">
        <div class="btn btn-sm btn-falcon-default disabled text-nowrap" style="margin: 0;">
            <slot />
        </div>
        <FileDownloadButton :download-url="docUrl" :file-name="fileName" style="margin: 0 1px;" />
        <PrintDropdownButton :print-url="docUrl" :paper-size="paperSize" class="btn-group" style="margin: 0;" />
    </div>
</template>

<script>
    import FileDownloadButton from './FileDownloadButton';
    import PrintDropdownButton from './PrintDropdownButton';

    export default {
        name: 'DocumentComboButton',
        components: {
            FileDownloadButton,
            PrintDropdownButton,
        },
        props: {
            docUrl: {
                type: String,
                required: true,
            },
            fileName: {
                type: String,
                required: true,
            },
            paperSize: {
                type: String,
                required: true,
            },
        },
    };
</script>
