<template>
    <LoadingSpinner v-if="loading" />
    <LoadingError v-else-if="error" />
    <div v-else>
        <div class="card mb-3">
            <div class="card-header">
                <div class="row">
                    <div class="col-auto">
                        <h5 class="text-nowrap">Shipment #{{ shipment.identifier }} Details</h5>
                        <p class="fs--1 mb-0">Created {{ shipment.created | formatISODate('PP, p') }}</p>
                    </div>
                    <div class="col text-right pl-2">
                        <DocumentComboButton :doc-url="`/shipments/${shipment.id}/packing_list/`"
                                             :file-name="`packing_list_${shipment.identifier}.pdf`"
                                             paper-size="LETTER"
                                             class="my-1"
                                             v-if="$auth.hasPermissions('read:shipments.packing_list', 'print:shipments.packing_list')">
                            Packing List
                        </DocumentComboButton>
                        <DocumentComboButton v-if="shipment.label_url && $auth.hasPermissions('read:shipments.shipping_label', 'print:shipments.shipping_label')"
                                             :doc-url="`/shipments/${shipment.id}/shipping_label/`"
                                             :file-name="`shipping_label_${shipment.identifier}.pdf`"
                                             paper-size="LETTER"
                                             class="my-1 ml-2">
                            Shipping Label
                        </DocumentComboButton>
                    </div>
                </div>
            </div>
            <div class="card-body bg-light border-top">
                <div class="row">
                    <div class="col-lg col-xxl-5">
                        <div class="row">
                            <div class="col-5 col-sm-4">
                                <p class="font-weight-semi-bold mb-1">Order</p>
                            </div>
                            <div class="col">
                                <router-link :to="{ name: 'order-details', params: { id: shipment.order.id }}"
                                             v-if="shipment.order">
                                    #{{ shipment.order.bigcommerce_order_id }}
                                </router-link>
                                <span v-else class="text-400">No Associated Order</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5 col-sm-4">
                                <p class="font-weight-semi-bold mb-1">Packages</p>
                            </div>
                            <div class="col">
                                {{ shipment.packages.length }}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg col-xxl-5">
                        <div class="row">
                            <div class="col-5 col-sm-4">
                                <p class="font-weight-semi-bold mb-1 text-nowrap">Tracking Num.</p>
                            </div>
                            <div class="col">
                                <span v-if="shipment.tracking_number && !shipment.tracking_url">{{ shipment.tracking_number }}</span>
                                <a v-else-if="shipment.tracking_number" :href="shipment.tracking_url">
                                    {{ shipment.tracking_number }}
                                </a>
                                <span v-else class="text-400">None on Record</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-5 col-sm-4">
                                <p class="font-weight-semi-bold mb-1">Carrier</p>
                            </div>
                            <div class="col">
                                <span v-if="shipment.carrier">
                                    {{ shipment.carrier.name }}
                                </span>
                                <span v-else class="text-400">None on Record</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-body">
                <table class="table table-striped border-bottom fs--1 mb-0">
                    <thead class="bg-300 text-1000 sticky-top">
                        <tr>
                            <th class="border-0">Items Shipped</th>
                            <th class="border-0 text-right white-space-nowrap">Updated in ERP</th>
                        </tr>
                    </thead>
                    <!-- Multiple <tbody> tags are valid according to MDN -->
                    <tbody v-for="pkg in shipment.packages" :key="pkg.id">
                        <tr class="bg-200 text-900">
                            <th class="border-0 py-1" colspan="2">
                                <div class="d-flex align-items-center">
                                    <span>Package #{{ pkg.box_number }}</span>
                                    <span class="ml-2 font-weight-normal">
                                        {{ pkg.length }}"&times;{{ pkg.width }}"&times;{{ pkg.height }}"
                                        {{ pkg.weight | formatDecimal(2, 1) }}lbs
                                    </span>
                                    <span class="ml-auto">
                                        <DocumentComboButton :doc-url="`/packages/${pkg.id}/carton_label/`"
                                                             :file-name="`carton_label_${shipment.identifier}-${pkg.box_number}.pdf`"
                                                             paper-size="LABEL"
                                                             v-if="$auth.hasPermissions('read:packages.carton_label', 'print:packages.carton_label')">
                                            Carton Label
                                        </DocumentComboButton>
                                    </span>
                                </div>
                            </th>
                        </tr>
                        <tr class="d-none" /> <!-- Corrects zebra striping -->
                        <tr v-for="item in pkg.items" :key="item.id">
                            <td>
                                <h6 class="mb-0">
                                    {{ item.product_variant.name }} &mdash; {{ item.product_variant.product_name }}
                                </h6>
                                <div v-for="(element, ai) in item.barcode_data" :key="ai">
                                    <span class="font-weight-semi-bold">{{ gs1CommonElements[ai] || ai }}:</span>&nbsp;
                                    <span v-if="gs1DateElements.includes(ai)">
                                        {{ element | formatDate('yyMMdd', 'PP') }}
                                    </span>
                                    <span v-else>{{ element }}</span>
                                </div>
                            </td>
                            <td class="white-space-nowrap text-right">
                                <div class="custom-control custom-switch" v-if="$auth.hasPermissions('update:items')">
                                    <input type="checkbox"
                                           v-model="item.is_updated_in_erp"
                                           @change="toggleErpStatus(item)"
                                           :disabled="item._loading"
                                           :id="`erp_${item.id}`"
                                           class="custom-control-input"
                                           aria-label="Updated in ERP">
                                    <label class="custom-control-label" :for="`erp_${item.id}`" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import { gs1CommonElements, gs1DateElements } from '../util';
    import DocumentComboButton from '../components/DocumentComboButton';
    import { getShipment } from '../services/ShipmentService';
    import LoadingError from '../components/LoadingError';
    import LoadingSpinner from '../components/LoadingSpinner';
    import { updateItem } from '../services/ItemService';

    export default {
        name: 'ShipmentDetails',
        components: {
            LoadingSpinner,
            LoadingError,
            DocumentComboButton,
        },
        data() {
            return {
                loading: true,
                error: false,
                shipment: null,
                gs1CommonElements,
                gs1DateElements,
            };
        },
        async mounted() {
            try {
                const { data } = await getShipment(this.$route.params.id);
                this.shipment = data;
            } catch {
                this.error = true;
            } finally {
                this.loading = false;
            }
        },
        methods: {
            async toggleErpStatus(item) {
                this.$set(item, '_loading', true);
                try {
                    await updateItem(item.id, { is_updated_in_erp: item.is_updated_in_erp });
                } catch {
                    item.is_updated_in_erp = !item.is_updated_in_erp; // Revert the value

                    this.$alerts.danger('An error occurred',
                                        'Unable to update item. Please verify inputted data and try again.');
                } finally {
                    item._loading = false;
                }
            },
        },
    };
</script>
