import axios from 'axios';

export const getPrinterList = () => axios.get('/printers/');

export const createPrinter = (data) => axios.post('/printers/', data);

export const deletePrinter = (id) => axios.delete(`/printers/${id}/`);

export const getDefaultPrinters = () => axios.get('/printers/defaults/');

export const setDefaultPrinter = (id) => axios.put(`/printers/${id}/default/`);

export const unsetDefaultPrinter = (id) => axios.delete(`/printers/${id}/default/`);

export const getUnconfiguredPrinterList = () => axios.get('/unconfigured_printers/');
