import axios from 'axios';

export const getShipmentList = () => axios.get('/shipments/');

export const getShipment = (id) => axios.get(`/shipments/${id}/`);

export const getRates = ({
    carrierId: carrier_id,
    shipFrom: ship_from,
    shipTo: ship_to,
    packages,
}) => (
    axios.post('/shipments/rates/', { carrier_id, ship_from, ship_to, packages })
);

export const createShipment = ({
    shipEngineId: shipengine_id,
    rateId: rate_id,
    orderId: order_id,
    carrierId: carrier_id,
    packages,
}) => (
    axios.post('/shipments/', { shipengine_id, rate_id, order_id, carrier_id, packages })
);
